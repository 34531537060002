<template>
    <b-collapse class="card" animation="slide" v-model="open" @click="open = !open">
        <template #trigger="props">
            <div class="card-header" role="button">
                <p class="card-header-title">
                    Delete
                </p>
                <a class="card-header-icon">
                    <b-icon
                        :icon="props.open ? 'menu-down' : 'menu-up'">
                    </b-icon>
                </a>
            </div>
        </template>
        <div class="card-content">
            <p>
                Click here to delete your account.
                <br />
                You'll need to confirm via email,
                and then your account will be permenantly deleted.
                <br />
                We cannot get your account back.
            </p>
            <form name="edit-account">
                <div class="field is-grouped mt-4">
                    <template v-if="confirm">
                        <div class="control">
                            <button
                                class="button is-warning"
                                type="button"
                                @click="requestDelete(); confirm = false"
                            >
                                Click to confirm
                            </button>
                        </div>
                        <div class="control">
                            <button
                                class="button is-primary"
                                type="button"
                                @click="confirm = false"
                            >
                                Cancel
                            </button>
                        </div>
                    </template>
                    <template v-else>
                        <div class="control">
                            <button
                                class="button is-danger"
                                type="button"
                                @click="confirm = true"
                            >
                                Delete
                            </button>
                        </div>
                    </template>
                </div>
            </form>
        </div>
    </b-collapse>
</template>

<script>
import {
// mapState,
// mapGetters,
// mapMutations,
    mapActions,
} from 'vuex';

export default {
    name: 'AccountDelete',
    data() {
        return {
            confirm: false,
            open: false,
        };
    },
    methods: {
        ...mapActions('user', [
            'requestDelete',
        ]),
    },
};
</script>
