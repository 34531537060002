<template>
    <div>
        <section v-if="is_logged_in">
            <div class="container">
                <div class="status p-4">
                    <a class="is-pulled-right" @click="handleLogout">
                        Logout
                        <b-icon
                            class="is-centered"
                            icon="logout"
                            size="is-small"
                        ></b-icon>
                    </a>

                    <p>Welcome {{user.username ? user.username : user.email}}</p>

                    <b-loading
                        :is-full-page="false"
                        v-model="is_loading"
                        :can-cancel="false"
                    ></b-loading>

                    <template v-if="!is_loading">
                        <div class="mt-4" v-if="is_verified">
                            <AccountRoutes />
                            <AccountEdit :user="user" class="mt-4" />
                            <AccountDelete class="mt-4" />
                        </div>

                        <div class="notification is-danger mt-4" v-else>
                            Your account is not verified,
                            please click the link in the verification email.
                            <br />
                            <a href="#" @click.prevent="reverify()">Click here to resend</a>
                        </div>
                    </template>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import {
    mapState,
    mapGetters,
    mapMutations,
    mapActions,
} from 'vuex';

import AccountEdit from '@/components/AccountEdit.vue';
import AccountDelete from '@/components/AccountDelete.vue';
import AccountRoutes from '@/components/AccountRoutes.vue';

import router from '../router';

export default {
    name: 'Account',
    components: {
        AccountEdit,
        AccountDelete,
        AccountRoutes,
    },
    async mounted() {
        // check logged in
        if (!this.$store.state.user.is_logged_in) {
            // if not logged in but we have a verification string, add to the query
            const query = {};
            if (typeof this.$route.query.verification !== 'undefined') {
                query.verification = this.$route.query.verification;
            }
            router.push({
                path: '/',
                query,
            });
            this.showLogin();
        } else {
            // check if we have user
            if (
                typeof this.user.is_verified === 'undefined'
            ) {
                this.is_loading = true;
                await this.getUserData();
            }
            this.is_loading = false;
            // if a verification script code is supplied attempt to verify
            if (typeof this.$route.query.verification !== 'undefined') {
                this.verifyUser(this.$route.query.verification);
            }
            // if a delete code is supplied attempt to delete user
            if (typeof this.$route.query.delete !== 'undefined') {
                await this.deleteUser(this.$route.query.delete);
                router.push({
                    path: '/',
                });
            }
        }
    },
    data() {
        return {
            is_loading: true,
        };
    },
    computed: {
        ...mapState({
            is_logged_in: (state) => state.user.is_logged_in,
            user: (state) => state.user.user,
        }),
        ...mapGetters('user', [
            'is_verified',
        ]),
        passwords_match() {
            return this.new_password === this.confirm_password;
        },
    },
    methods: {
        ...mapMutations('user', [
            'setLoggedIn',
            'showLogin',
        ]),
        ...mapActions('user', [
            'logout',
            'getUserData',
            'verifyUser',
            'deleteUser',
            'reverify',
        ]),
        async handleLogout() {
            // wait for the logout
            await this.logout();
            // then go home
            router.push('/');
        },
    },
};
</script>
