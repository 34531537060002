<template>
    <b-collapse class="card" animation="slide" v-model="open" @click="open = !open">
        <template #trigger="props">
            <div class="card-header" role="button">
                <p class="card-header-title">
                    Edit
                </p>
                <a class="card-header-icon">
                    <b-icon
                        :icon="props.open ? 'menu-down' : 'menu-up'">
                    </b-icon>
                </a>
            </div>
        </template>
        <div class="card-content">
            <form name="edit-account">
                <div class="field">
                    <label class="label">Email</label>
                    <div class="control has-icons-left has-icons-right">
                        <input
                            class="input"
                            disabled
                            type="email"
                            :value="user.email"
                        >
                    </div>
                    <p class="help">
                        This is the email address you used to register the account
                    </p>
                </div>

                <div class="field">
                    <label class="label">Username</label>
                    <div class="control has-icons-left has-icons-right">
                        <input
                            class="input"
                            type="text"
                            placeholder="Create a username"
                            v-model="new_username"
                        >
                    </div>
                    <p class="help">An optional way for us to address you</p>
                </div>

                <div class="field">
                    <label class="label">Change password</label>
                    <div class="control has-icons-left has-icons-right">
                        <input
                            class="input"
                            type="password"
                            placeholder="Current password"
                            v-model="current_password"
                        >
                    </div>
                    <p class="help">Enter and confirm new password</p>
                    <div class="control has-icons-left has-icons-right">
                        <input
                            class="input"
                            type="password"
                            placeholder="New password"
                            v-model="new_password"
                            :disabled="current_password === ''"
                        >
                    </div>
                    <div class="control has-icons-left has-icons-right">
                        <input
                            class="input"
                            type="password"
                            placeholder="Confirm new password"
                            v-model="confirm_password"
                            :disabled="current_password === ''"
                        >
                    </div>
                    <p
                        class="help is-danger"
                        v-if="!passwords_match && this.confirm_password !== ''"
                    >
                        Passwords must match
                    </p>
                    <p
                        class="help is-danger"
                        v-if="passwords_match && this.confirm_password !== '' && !valid_password"
                    >
                        {{password_pattern_message}}
                    </p>
                </div>

                <div class="field is-grouped">
                    <div class="control">
                        <button
                            class="button is-link"
                            type="button"
                            @click="save"
                            :disabled="!passwords_match || !valid_password"
                        >
                            Save
                        </button>
                    </div>
                    <div class="control">
                        <button
                            type="button"
                            class="button is-link is-light"
                            @click="cancel"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </b-collapse>
</template>

<script>
import {
    mapState,
    // mapGetters,
    // mapMutations,
    mapActions,
} from 'vuex';

export default {
    name: 'AccountEdit',
    props: {
        user: Object,
    },
    data() {
        return {
            current_password: '',
            new_username: '',
            new_password: '',
            confirm_password: '',
            open: false,
        };
    },
    mounted() {
        if (this.user.username !== '') {
            this.new_username = this.user.username;
        }
    },
    computed: {
        ...mapState('user', [
            'password_pattern',
            'password_pattern_message',
        ]),
        passwords_match() {
            return this.new_password === this.confirm_password;
        },
        valid_password() {
            const re = new RegExp(this.password_pattern, 'g');
            return this.new_password.match(re);
        },
    },
    methods: {
        ...mapActions('user', [
            'update',
        ]),
        save() {
            const update_data = {
                username: this.new_username,
            };

            // send password data if it is filled
            if (
                this.current_password !== ''
                && this.new_password !== ''
                && this.passwords_match
                && this.valid_password
            ) {
                update_data.current_password = this.current_password;
                update_data.new_password = this.new_password;
                update_data.confirm_password = this.confirm_password;
            }

            this.update(update_data);
        },
        cancel() {
            // reset form fields
            this.new_username = this.user.name;
            this.new_password = '';
            this.current_password = '';
            this.confirm_password = '';
        },
    },
};
</script>
