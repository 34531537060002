<template>
    <b-collapse class="card" animation="slide">
        <template #trigger="props">
            <div class="card-header" role="button">
                <p class="card-header-title">
                    Your Routes
                </p>
                <a class="card-header-icon has-text-black">
                    <b-icon
                        :icon="props.open ? 'menu-down' : 'menu-up'">
                    </b-icon>
                </a>
            </div>
        </template>
        <div class="card-content">
            <div class="columns">
                <div class="column">
                    <h4 class="title is-4">Favourite Routes</h4>
                    <RouteCard
                        :route="route"
                        v-for="route in favouriteRoutes"
                        :key="route.id"
                    ></RouteCard>
                    <p v-if="!favouriteRoutes.length">
                        You haven't favourited any routes yet,
                        <br />
                        visit the
                        <router-link to="/routes">search page</router-link>
                        to favourite some!
                    </p>
                </div>
                <div class="column">
                    <h4 class="title is-4">Rated Routes</h4>
                    <RouteCard
                        :route="route"
                        v-for="route in ratedRoutes"
                        :key="route.id"
                        :show_favourite="false"
                        :show_rating="true"
                    ></RouteCard>
                    <p v-if="!ratedRoutes.length">
                        <router-link to="/routes">Browse some routes now</router-link>
                        and rate the ones you love best so we can tailor new routes!
                    </p>
                </div>
            </div>
        </div>
    </b-collapse>
</template>

<script>
import RouteCard from '@/components/RouteCard.vue';

import {
    mapState,
    // mapGetters,
    // mapMutations,
    mapActions,
} from 'vuex';

export default {
    name: 'AccountRoutes',
    components: {
        RouteCard,
    },
    data() {
        return {};
    },
    mounted() {
        // if we have no routes in the app, load them
        if (!this.routes.length) {
            this.fetchRoutes({});
        }
    },
    computed: {
        ...mapState({
            routes: (state) => state.route.arr_routes,
        }),
        favouriteRoutes() {
            return this.routes.filter((route) => route.is_favourite);
        },
        ratedRoutes() {
            return this.routes.filter((route) => route.rating);
        },
    },
    methods: {
        ...mapActions('route', [
            'fetchRoutes',
        ]),
    },
};
</script>

<style scoped lang="scss">
    .card-header {
        background-color: $pink;
    }
</style>
