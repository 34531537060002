<template>
    <div class="container">
        <div class="reset-form p-4">
            <h2 class="title is-2">Reset password</h2>

            <form @submit="validate">
                <b-field
                    :type="password_type"
                    :message="password_msg"
                >
                    <b-input type="password"
                        v-model="password"
                        password-reveal
                        placeholder="Password"
                    >
                    </b-input>
                </b-field>
                <b-field
                    :type="password_type"
                    :message="password_msg"
                >
                    <b-input type="password"
                        :disabled="!valid_password"
                        v-model="confirm_password"
                        password-reveal
                        placeholder="Confirm Password"
                    >
                    </b-input>
                </b-field>
                <p
                    class="help is-danger"
                    v-if="!passwords_match && this.confirm_password !== ''"
                >
                    Passwords must match
                </p>
                <p
                    class="help is-danger"
                    v-if="!valid_password"
                >
                    {{password_pattern_message}}
                </p>
                <button
                    class="button btn-hollow"
                    type="button"
                    :disabled="disabled"
                    @click="validate"
                >
                    Reset
                </button>
            </form>
        </div>
    </div>
</template>

<script>
import {
    mapState,
    // mapGetters,
    // mapMutations,
    mapActions,
} from 'vuex';

import router from '../router';

export default {
    name: 'Account',
    async mounted() {
        // check logged in
        if (!this.$store.state.user.is_logged_in) {
            if (typeof this.$route.query.key === 'undefined') {
                // without a key we can't reset the password
                router.push({
                    path: '/',
                });
            }
        } else {
            // logged in users shouldn't be on this page
            router.push({
                path: '/account',
            });
        }
    },
    data() {
        return {
            is_loading: true,
            password: '',
            confirm_password: '',
            password_msg: '',
            password_type: '',
        };
    },
    computed: {
        ...mapState('user', [
            'password_pattern',
            'password_pattern_message',
            'is_logged_in',
        ]),
        passwords_match() {
            return this.password === this.confirm_password;
        },
        valid_password() {
            const re = new RegExp(this.password_pattern, 'g');
            return this.password.match(re);
        },
        disabled() {
            return !this.valid_password
                || !this.passwords_match;
        },
    },
    methods: {
        ...mapActions('user', [
            'resetPassword',
        ]),
        validate() {
            if (!this.disabled) {
                this.resetPassword({
                    key: this.$route.query.key,
                    password: this.password,
                    confirm_password: this.confirm_password,
                });
            }
        },
    },
};
</script>

<style scoped>
    .reset-form {
        max-width: 600px;
        margin: 0 auto;
    }
</style>
